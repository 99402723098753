import axios from 'axios'
import React, { lazy, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './BlikPage.module.scss'
// import { MuiOtpInput } from 'mui-one-time-password-input'
import { pay } from '../../model/cardSagas/cardActions'

import { ReactComponent as BlikLogo } from '../../assets/images/blik.svg'; // Adjust the path accordingly
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputBase from '@material-ui/core/InputBase';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';


export const BlikPage = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [submitted, setSubmitted] = useState(false)
    const inputRefs = useRef([]);
    const dispatch = useDispatch()

    // Handle OTP input (works for both desktop and mobile)
    const handleInput = (e, index) => {
      const value = e.target.value;
  
      if (/^[0-9]$/.test(value)) {
        let newOtp = [...otp];
  
        newOtp[index] = value;
        setOtp(newOtp);
  
        // Move to the next input
        if (index < otp.length - 1) {
          inputRefs.current[index + 1].focus();
        }
  
        // Auto submit if the last field is filled
        if (index === otp.length - 1) {
            handleSubmit(newOtp);
        }
      } else {
        e.target.value = ""; // Clear the input if the value is invalid
      }
    };
  
    // Handle backspace for desktop (keydown)
    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace") {
        let newOtp = [...otp];
  
        // If the current field has a value, clear it
        if (otp[index] !== "") {
          newOtp[index] = "";
          setOtp(newOtp);
        } 
        // If the current field is empty, move to the previous field and clear that
        else if (index > 0) {
          inputRefs.current[index - 1].focus();
          newOtp[index - 1] = "";
          setOtp(newOtp);
        }
      } else {
        if (e.target.value != "") {
            e.target.value = ""
            handleInput(e, index)
        }
      }
    };
  
    // Handle backspace for mobile (input event)
    const handleBackspaceMobile = (e, index) => {
      if (e.inputType === "deleteContentBackward") {
        let newOtp = [...otp];
  
        // If current field has a value, clear it
        if (otp[index] !== "") {
          newOtp[index] = "";
          setOtp(newOtp);
        } 
        // Move to the previous field if necessary
        else if (index > 0) {
          inputRefs.current[index - 1].focus();
          newOtp[index - 1] = "";
          setOtp(newOtp);
        }
      }
    };
  
    // Handle paste event
    const handlePaste = (e) => {
      const pasteData = e.clipboardData.getData("text").split("").slice(0, otp.length);
      if (pasteData.length === otp.length) {
        setOtp(pasteData);
        inputRefs.current[otp.length - 1].focus();
      }
    };
  
    // Simulate submit
    const handleSubmit = async (otp) => {
        if (submitted) {
            return
        }
        setSubmitted(true)

        const urlParamsObj = new URLSearchParams(window.location.search);
        const urlParams = {}

        for (const key of urlParamsObj.keys()) {
            urlParams[key] = urlParamsObj.get(key)
        }

        await axios.post('/form/pay', {
            invoice_id: urlParams['invoice_id'],
            merchant_id: urlParams['merchant_id'],
            endpoint_id: urlParams['endpoint_id'],
            amount: urlParams['amount'],
            order: urlParams['order'],
            signature: urlParams['signature'],
            currency: urlParams['currency'],
            otp: otp.join(""),
        })
        .then(function (response) {
            console.log(response.data)
            
            if (response?.data?.status == 'redirect') {
                window.location.href = response.data.url;
            } else {
                console.log("Unknown status: " + response?.data?.payment_status);
            }
                              
        })
        .catch(function (error) {
            console.log(error);
        });

    };
  
    return (
      <div className={styles.otpContainer}>        
        <BlikLogo width={150} />        
        <h2 className={styles.otpHeader}>Enter OTP</h2>
        <div className={styles.otpField}>
          {otp.map((value, index) => (
            <input
              key={index}
              disabled={submitted}
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handleInput(e, index)}  // Works for both desktop and mobile
              onKeyDown={(e) => handleKeyDown(e, index)}  // Desktop backspace handling
              onInput={(e) => handleBackspaceMobile(e, index)}  // Mobile backspace handling
              onPaste={handlePaste}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>

        <p className={styles.termsLink}>
          By continuing, you agree to our <a href="https://www.mobilum.com/docs/Mobilum-Terms-and-Conditions-of-Services-in-Partners-Applications.pdf" target="_blank">Terms and Conditions</a>.
        </p>

      </div>
    );
  };
  